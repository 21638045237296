import React from "react";
import ReactDOM from "react-dom";
import Im from "traec/immutable";
import Traec from "traec";

import { connect } from "react-redux";
import BaseFormConnected from "traec-react/utils/form";
import { BSCard, BSBtn } from "traec-react/utils/bootstrap";

import CompanyProjects from "../projects";
import CompanyChildren from "../children";

import { CompanyPermission, companyPermissionFilter } from "traec/utils/permissions/company";
import { Spinner } from "traec-react/utils/entities";
import { companyFields } from "../form";
import { setSideBarItems } from "traec-react/sideBar";
import { ErrorBoundary } from "traec-react/errors";

import { SetMetaDataFields } from "AppSrc/forms/meta";
import { TechnicalDetails } from "./cache";
import TipsSpinner from "AppSrc/utils/spinners/tipsSpinner";

const styleFields = {
  headerColor: { label: "Header color", value: "", class: "col" },
  buttonColor: { label: "Button color", value: "", class: "col" },
  navBarColor: { label: "Navbar color", value: "", class: "col", endRow: true },
  strapline1: { label: "Strapline 1", value: "", class: "col" },
  strapline2: { label: "Strapline 2", value: "", class: "col", endRow: true },
  strapline2link: { label: "Strapline Link (url)", value: "", class: "col", endRow: true },
  navbarLogo: { label: "Navbar Logo (url)", value: "", class: "col", endRow: true },
  straplineImages: { label: "Strapline images (comma-separated urls)", value: "", class: "col", endRow: true },
  iconPath: { label: "Issue Icon Path (url)", value: "", class: "col", endRow: true }
};

const setStylesBelow = (e, companyId) => {
  e.preventDefault();
  let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });
  fetch.updateFetchParams({
    body: { type: "COPY_STYLES_TO_DESCENDANTS" }
  });
  fetch.dispatch();
};

function CompanyStyleForm({ companyId, company, show }) {
  if (!show) {
    return null;
  }

  let fetch = new Traec.Fetch("company", "patch", { companyId });
  fetch.updateFetchParams({
    preFetchHook: body => {
      let newBody = {
        meta_json: { styles: body }
      };
      //console.log("SENDING PATCH DATA", newBody, body);
      return newBody;
    }
  });

  return (
    <div className="row">
      <BSCard
        widthOffset="col-sm-12"
        title="Account styles"
        button={
          <BSBtn
            onClick={e => {
              setStylesBelow(e, companyId);
            }}
            text="Push to all below"
          />
        }
        body={
          <BaseFormConnected
            params={fetch.params}
            fields={styleFields}
            initFields={company.getInPath("meta_json.styles") || null}
            forceShowForm={true}
          />
        }
      />
    </div>
  );
}

class CompanyDetails extends React.Component {
  constructor(props) {
    super(props);

    let { companyId } = props.match.params;
    let fetch = new Traec.Fetch("company", "put", { companyId });

    this.state = {
      formParams: fetch.params,
      initFormFields: Im.Map(),
      setInitFields: false
    };

    // Data required from the API for this Component
    this.requiredFetches = [new Traec.Fetch("company", "list")];

    // Action bindings
    this.setPostData = this.setPostData.bind(this);
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
    let { companyId } = this.props;
    let fetch = new Traec.Fetch("company", "put", { companyId });

    if (fetch.params.fetchParams.url !== this.state.formParams.fetchParams.url) {
      this.setState({ formParams: fetch.params });
    }
  }

  /* ACTIONS */

  setSideBar(forceUpdate = false) {
    let sideBarLinks = this.sideBarLinks();
    if ((!this.state.setSideBar && sideBarLinks) || forceUpdate) {
      //console.log("Updating sidebar and navbar")
      this.setState({ setSideBar: true });
      this.props.dispatch(setSideBarItems(sideBarLinks));
    }
  }

  sideBarLinks() {
    let { companyId } = this.props;
    let companyBase = `/company/${companyId}`;
    let items = [
      { label: "Corporate Settings", to: "#" },
      { label: "Indicators", to: `${companyBase}/indicators`, octicon: "issue-closed", requiresAdmin: true },
      { label: null },
      { label: null },
      { label: "Corporate Pages", to: "#" },
      { label: "Corporate Dashboard", to: companyBase, octicon: "home" },
      {
        label: "Users",
        to: `${companyBase}/members`,
        octicon: "organization",
        requiresAdmin: false,
        requiredActions: ["READ_COMPANY_MEMBER"]
      }
    ];
    return companyPermissionFilter(companyId, items);
  }

  setInitFields() {
    let { company } = this.props;
    if (!company) {
      return companyFields;
    }
    //Object.assign(companyFields.name, {value: "Test name"})
    let setFields = {};
    Object.assign(setFields, companyFields);
    Object.keys(setFields).map(key => {
      if (key.startsWith("meta_")) {
        let metaKey = key.split("meta_")[1];
        setFields[key].value = company.getIn(["meta_json", metaKey]) || setFields[key].value;
      } else {
        setFields[key].value = company.get(key) || setFields[key].value;
      }
    });
    return setFields;
  }

  setPostData(post) {
    // Get the fields that are prefixed with meta_ and put them into the meta_json object
    let postData = {};
    let metaData = {};
    for (let [key, value] of Object.entries(post)) {
      if (key.startsWith("meta_")) {
        let metaKey = key.split("meta_")[1];
        Object.assign(metaData, { [metaKey]: value });
      } else {
        Object.assign(postData, { [key]: value });
      }
    }
    Object.assign(postData, { meta_json: metaData });
    return postData;
  }

  render() {
    let { companyId, company, is_tenant_admin, companyList } = this.props;

    if (!company) {
      return <TipsSpinner message={"Loading..."} />;
    }

    let childIds = (company || Traec.Im.Map()).get("childids") || Traec.Im.List();
    let children = childIds.map((childId, i) => companyList.get(childId));

    return (
      <CompanyPermission companyId={companyId} requiresAdmin={true}>
        <h3>Corporate Settings</h3>

        <div className="row">
          <BSCard
            widthOffset="col-sm-12"
            title="Corporate details"
            body={
              <BaseFormConnected
                params={this.state.formParams}
                fields={this.setInitFields()}
                prePostHook={this.setPostData}
                forceShowForm={true}
              />
            }
          />
        </div>

        {/* Render Company Business Unit Panel if Admin*/}
        <ErrorBoundary>
          <div className="row">
            <BSCard
              widthOffset="col-sm-12"
              title="Meta data"
              body={
                <SetMetaDataFields
                  saveMetaFetchProps={{
                    handler: "company",
                    method: "patch",
                    params: { companyId }
                  }}
                  pushMetaFetchProps={{
                    handler: "company_dispatch",
                    method: "post",
                    params: { companyId }
                  }}
                  metaJson={company.get("meta_json")}
                />
              }
            />
          </div>
        </ErrorBoundary>

        {/* Render Company Project Panel if Admin*/}
        <ErrorBoundary>
          <CompanyProjects companyId={companyId} meta={company.get("meta_json")} />
        </ErrorBoundary>

        {/* Render Company Business Unit Panel if Admin*/}
        <ErrorBoundary>
          <div className="row">
            <CompanyChildren company={company} children={children} />
          </div>
        </ErrorBoundary>

        <ErrorBoundary>
          <CompanyStyleForm companyId={companyId} company={company} show={is_tenant_admin} />
        </ErrorBoundary>

        <ErrorBoundary>
          <TechnicalDetails {...this.props} />
        </ErrorBoundary>
      </CompanyPermission>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { companyId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let company = state.getInPath(`entities.companies.byId.${companyId}`);
  let companyList = state.getInPath("entities.companies.byId");

  let is_tenant_admin = state.getInPath("auth.user.is_tenant_superuser");

  return { companyId, company, companyList, is_tenant_admin };
};

export default connect(mapStateToProps)(CompanyDetails);
