import React from "react";
import ReactDOM from "react-dom";
import Traec from "traec";

import { connect } from "react-redux";
import { BSBtnDropdown, BSCard } from "traec-react/utils/bootstrap";

import { CompanyPermission } from "traec/utils/permissions/company";
import { Spinner } from "traec-react/utils/entities";
import CompanyAvailableIndicators from "./available";
import CompanyIndicatorRow from "./indicatorRow";
import TipsSpinner from "AppSrc/utils/spinners/tipsSpinner";
import { alertSuccess } from "traec-react/utils/sweetalert";

const pushAllToChildren = (companyId, _type = "Indicator") => {
  let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });
  let actionName = `ADD_${_type.toUpperCase()}_TO_ALL`;
  let payload = {
    [`${_type.toLowerCase()}Id`]: "all"
  };
  fetch.updateFetchParams({
    body: {
      type: actionName,
      payload
    },
    postSuccessHook: data => {
      alertSuccess({
        title: `${_type} push success`,
        text: `Succesfully start asynchronous push of all ${_type} to children.\nThis will take approximately ${(data
          ?.payload?.estTime || 0) / 60 || "unknown"} minutes to complete.`,
        onConfirm: e => {
          location.reload();
        }
      });
    }
  });
  fetch.dispatch();
};

class CompanyIndicators extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddExistingForm: false,
      showAddForm: false
    };

    // Data required from the API for this Component
    this.requiredFetches = [new Traec.Fetch("company_indicator", "list"), new Traec.Fetch("company_target", "list")];

    // Bindings for actions
    this.toggleAddExisting = this.toggleAddExisting.bind(this);
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  toggleAddExisting(e) {
    e.preventDefault();
    this.setState({ showAddExistingForm: !this.state.showAddExistingForm });
  }

  dropDownLinks() {
    let { companyId } = this.props;
    return [
      { name: "From Project Indicators", onClick: this.toggleAddExisting },
      { name: "Push all indicators to Children", onClick: e => pushAllToChildren(companyId, "Indicator") },
      { name: "Push all targets to Children", onClick: e => pushAllToChildren(companyId, "Target") }
      //{ name: "Create new", onClick: this.deleteItem },
    ];
  }

  render_loading() {
    return <TipsSpinner message={"Loading Indicator List. This might take a moment"} />;
  }

  render_add_existing_list() {
    let { companyId } = this.props;
    if (!this.state.showAddExistingForm) {
      return null;
    }
    return (
      <BSCard
        title="All Available Indicators from Sub-Projects"
        body={<CompanyAvailableIndicators companyId={companyId} toggleShowHandler={this.toggleAddExisting} />}
      />
    );
  }

  render_add_form() {
    if (!this.state.showAddExistingForm) {
      return null;
    }
    let { projectId, trackerId, commitId, baseMetrics, dispatch } = this.props;
    return (
      <div className="row">
        <CreateIndicatorForm
          projectId={projectId}
          trackerId={trackerId}
          commitId={commitId}
          baseMetrics={baseMetrics}
          dispatch={dispatch}
          toggleShowHandler={e => {
            e.preventDefault();
            this.setState({ showAddForm: !this.state.showAddForm });
          }}
        />
      </div>
    );
  }

  render_indicator_list() {
    let { companyId, companyIndicators, baseMetrics, metricTargetMap } = this.props;
    if (!companyIndicators) {
      return null;
    }
    return companyIndicators
      .toList()
      .sortBy(i => i.getInPath("resultBaseMetric.category"))
      .map((indicator, i) => {
        let metricId = indicator.getInPath("resultBaseMetric.uid");
        let indicatorTarget = indicator.set("metricTarget", metricTargetMap.get(metricId));
        return (
          <CompanyIndicatorRow key={i} companyId={companyId} indicator={indicatorTarget} baseMetrics={baseMetrics} />
        );
      });
  }

  static render_indicator_list_headers() {
    return (
      <div className="row">
        <div className="col-sm-4">
          <b>{"Name"}</b>
        </div>
        <div className="col-sm-2">
          <b>{"Category"}</b>
        </div>
        <div className="col-sm-1">
          <b>{"Factor"}</b>
        </div>
        <div className="col-sm-3">
          <b>{"Numerators & Denominators"}</b>
        </div>
        <div className="col-sm-1">
          <b>Target</b> | <b>Warn</b>
        </div>
        <div className="col-sm-1">
          <b>{""}</b>
        </div>
      </div>
    );
  }

  render() {
    let { companyId, company } = this.props;

    if (!company) {
      return this.render_loading();
    }

    return (
      <CompanyPermission companyId={companyId} requiresAdmin={true}>
        <h3>Corporate Indicators</h3>
        <p>Set Corporate Indicators here that will be shown on the Dashboard</p>
        <BSBtnDropdown header={"Add an Indicator"} links={this.dropDownLinks()} />
        <div style={{ clear: "both" }} />
        {this.render_add_existing_list()}
        <br />
        <br />
        {CompanyIndicators.render_indicator_list_headers()}
        <hr />
        {this.render_indicator_list()}
      </CompanyPermission>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { companyId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let company = state.getInPath(`entities.companies.byId.${companyId}`);
  let companyIndicators = state.getInPath(`entities.companyObjects.byId.${companyId}.indicators`);
  let baseMetrics = state.getInPath(`entities.baseMetrics.byId`);

  // Get the targets pulled down
  let companyTargets = state.getInPath(`entities.companyObjects.byId.${companyId}.targets`) || Traec.Im.Map();
  let metricTargetMap = companyTargets.mapEntries(([k, v]) => [v.getInPath("metric.uid"), v]);

  return { companyId, company, companyIndicators, baseMetrics, metricTargetMap };
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(CompanyIndicators);
