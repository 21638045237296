import React from "react";
import Traec from "traec";
import { ErrorBoundary } from "traec-react/errors";
import { BSCard, BSBtn } from "traec-react/utils/bootstrap";

const clearDashboardCache = props => {
  let { companyId } = props;
  let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });
  fetch.updateFetchParams({
    body: {
      type: "SUSTOOL_DASHBOARD_DATA",
      payload: {
        part: "CLEAR_CACHE"
      }
    }
  });
  //console.log("Dispatching Company clearDashboardCache");
  fetch.dispatch();
};

function RenderID({ title, id }) {
  return (
    <tr>
      <td>
        <b>{title}</b>
      </td>
      <td>{id}</td>
    </tr>
  );
}

export function TechnicalDetails(props) {
  let { companyId } = props;
  return (
    <ErrorBoundary>
      <BSCard
        widthOffset="col-sm-12"
        title="Technical details"
        body={
          <table width="100%">
            <tbody>
              <RenderID title="Full Company ID" id={companyId} />
              <tr>
                <td>
                  <button className="btn btn-sm btn-warning" onClick={() => clearDashboardCache(props)}>
                    Clear dashboard cache
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        }
      />
    </ErrorBoundary>
  );
}
