import React from "react";
import { connect } from "react-redux";
import Traec from "traec";
import { Spinner } from "traec-react/utils/entities";
import GeoReport, { ColorScale, colorScale } from "AppSrc/report/geo";

class CompanyMap extends React.Component {
  constructor(props) {
    super(props);

    this.requiredFetches = [
      new Traec.Fetch(
        "company",
        "read",
        {},
        {
          preUpdateHook: args => ({ ...args, companyId: this.props.match.params._companyId })
        }
      ),
      new Traec.Fetch(
        "company_dispatch",
        "post",
        {},
        {
          preDispatchHook: action => {
            let { fetchParams, stateParams } = action;
            // Update the fetchParams before dispatch
            Object.assign(fetchParams, {
              body: {
                type: "GET_CACHE_ITEM",
                payload: {
                  path: `/geodata/latest`
                }
              }
            });
            // Update the stateSetFunc
            Object.assign(stateParams, {
              stateSetFunc: (state, action) => {
                let data = action.payload;
                return state.setInPath(`dispatch.${fetchParams.url}.GET_CACHE_ITEM./geodata/latest`, data);
              }
            });
            return action;
          }
        }
      )
    ];
  }

  componentDidMount() {
    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate() {
    Traec.fetchRequiredFor(this);
  }

  spinnerOrMap(data) {
    if (!data) {
      return <Spinner explanation="Loading data" timedOutComment="No data" />;
    } else {
      return <GeoReport data={data.toJS()} hasSideBar={this.props.hasSideBar} draggedSize={this.props.draggedSize} />;
    }
  }
  render() {
    let { data, company } = this.props;

    let provider = company ? company.getInPath("meta_json.gisdata.provider") : null;
    let title = (company ? company.getInPath("meta_json.gisdata.title") : null) || "GIS Related Data";

    return (
      <React.Fragment>
        <h3>{title}</h3>

        {provider ? <p>The data for these maps was provided by {provider}.</p> : null}

        <p>The shapefiles for the maps have been taken from the Office for National Statistics as follows:</p>
        <ul>
          <li>
            <a
              target="_blank"
              href="https://geoportal.statistics.gov.uk/datasets/european-electoral-regions-december-2018-boundaries-uk-bfe?geometry=-85.356%2C46.017%2C80.494%2C63.434"
            >
              Electoral Regions
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://geoportal.statistics.gov.uk/datasets/counties-and-unitary-authorities-december-2017-full-extent-boundaries-in-uk-wgs84?geometry=-46.578%2C50.148%2C36.346%2C59.017"
            >
              Counties and Unitary Authorities
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://geoportal.statistics.gov.uk/datasets/westminster-parliamentary-constituencies-december-2017-full-extent-boundaries-in-the-uk?geometry=-85.356%2C46.017%2C80.494%2C63.434"
            >
              Westminster Parliamentary Constituencies
            </a>{" "}
          </li>
        </ul>

        <p>Use the dropdown menus to navigate the data for various regions and quantities.</p>

        {this.spinnerOrMap(data)}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  //let { companyId } = ownProps.match.params;
  const { companyId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let company = state.getInPath(`entities.companies.byId.${companyId}`);
  let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });
  let data = state.getInPath(`entities.dispatch.${fetch.url}.GET_CACHE_ITEM./geodata/latest.payload.data`);
  return { companyId, company, data };
};

const mapDispatchToProps = dispatch => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(CompanyMap);
