import React, { useEffect } from "react";
import { DateRangePicker } from "traec-react/utils/dateRangePicker";
import Moment from "moment";

export const ReportingPeriodSelector = props => {
  //console.log("ReportingPeriodSelector has props", props);
  const [state, setState] = React.useState({
    fromDate: props.fromDate
      ? new Moment(props.fromDate).toDate()
      : new Moment(props.toDate).subtract(12, "months").toDate(),
    toDate: new Moment(props.toDate).toDate()
  });

  useEffect(() => {
    //console.log("Using effect", state.fromDate, state.toDate);
    props.handleDateChange(state.fromDate, "fromDate");
    props.handleDateChange(state.toDate, "toDate");
  }, []);

  const setDateRangeValue = e => {
    setState({ ...state, [e.target.name]: e.target.value });
    props.handleDateChange(e.target.value, e.target.name);
  };

  return <DateRangePicker onChange={setDateRangeValue} fromDateValue={state.fromDate} toDateValue={state.toDate} />;
};
