import React, { useState, useEffect } from "react";
import Traec from "traec";
import { setAndShowModal, clearModal } from "AppSrc/utils/modal";
import Papa from "papaparse";

const MODAL_ID = "engagementReport";
const INDUSTRY_DASHBOARD_ID = "30edb62e";

const companyDispatchCSVToState = (companyId, dispatchType, setState) => {
  let fetch = new Traec.Fetch("company_dispatch", "post", { companyId });

  fetch.updateFetchParams({
    body: {
      type: dispatchType,
      payload: {}
    }
  });

  fetch
    .rawFetch({ updateBody: true })
    .then(response => response.text())
    .then(data => {
      let csv = Papa.parse(data);
      //console.log("Got parsed csv data", dispatchType, csv.data);
      setState(Traec.Im.fromJS(csv.data));
    });
};

function EngagementReport({ companyId, siteInvites }) {
  let [projectMembers, setProjectMembers] = useState(Traec.Im.List());
  let [reportStatuses, setReportStatuses] = useState(Traec.Im.List());

  useEffect(() => {
    companyDispatchCSVToState(INDUSTRY_DASHBOARD_ID, "PROJECT_MEMBERS_CSV", setProjectMembers);
    companyDispatchCSVToState(INDUSTRY_DASHBOARD_ID, "REPORT_STATUS_CSV", setReportStatuses);
  }, []);

  let isLoading = !projectMembers.size || !reportStatuses.size;
  if (isLoading) {
    return (
      <div>
        <p>Generating engagement report</p>
        <div className="spinner-border spinner-border-sm" />
      </div>
    );
  }

  // Now you have the 3 tables in Javascript and you can do the logic

  // finally return the report back to the user
  return <button onClick={() => downloadReport()}>Download</button>;
}

export const generateEngagementReport = (companyId, siteInvites) => {
  //console.log("GENERATING ENGAGEMENT REPORT", companyId, siteInvites?.toJS());
  setAndShowModal(MODAL_ID, {
    title: "Engagement report",
    body: <EngagementReport companyId={companyId} siteInvites={siteInvites} />
  });
};
